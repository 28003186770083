import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FooterSection,FooterCol,FooterLogo,FooterTitle,FooterPara,IconTextLayout,PhoneIcon,
    EmailIcon,PhoneEmailText,FbIcon,TwitterIcon,LinkedinIcon,
    PinterestIcon,FooterUl,FooterLi,FooterMenu,FooterSocial,BottomFooterPara,SocialLayout,
    BottomFooterWrapper,BottomLink} from './footer.style';
import { useStaticQuery, graphql } from 'gatsby';
import ContactFormFooter from "../ContactFormFooter";

const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
    query {
        headerFooterJson{
                Footer{
                    FooterCol1{
                        FooterTitle
                        FooterLogo
                        FooterPara
                        Phone
                        Email
                    }
                    FooterCol2{
                        FooterTitle
                        FooterUl1{
                            FooterHref
                            FooterMenu
                        }
                        FooterUl2{
                            FooterHref
                            FooterMenu
                        }
                    }
                    FooterCol3{
                        FooterTitle
                        FooterUl{
                            FooterMenu
                        }
                    }
                    FooterCol4{
                        FooterTitle
                    }
                    BottomFooterPara
                    BottomFooterName
                    BottomFooterLink
                    facebooklink
                    twitterlink
                    linkedinlink
                    pinterestlink
                }
            }
        }
  `);
    return (
            <div>
            <FooterSection>
                <Container>
                    <Row>
                        <Col md="6" lg="3">
                            <FooterCol>
                                <FooterLogo src={JSONData.headerFooterJson.Footer.FooterCol1.FooterLogo} alt=""/>
                                <FooterPara>
                                    {JSONData.headerFooterJson.Footer.FooterCol1.FooterPara}
                                </FooterPara>
                                <IconTextLayout>
                                    <PhoneIcon />
                                    <PhoneEmailText>{JSONData.headerFooterJson.Footer.FooterCol1.Phone}</PhoneEmailText>
                                </IconTextLayout>
                                <IconTextLayout>
                                    <EmailIcon />
                                    <PhoneEmailText>{JSONData.headerFooterJson.Footer.FooterCol1.Email}</PhoneEmailText>
                                </IconTextLayout>
                            </FooterCol>
                        </Col>
                        <Col md="6" lg="3">
                            <FooterCol>
                                <FooterTitle>{JSONData.headerFooterJson.Footer.FooterCol2.FooterTitle}</FooterTitle>
                                <Row>
                                    <Col md="5" lg="5">
                                        <FooterUl>
                                        {
                                            JSONData.headerFooterJson.Footer.FooterCol2.FooterUl1.map((item,idx) => {
                                            return <FooterLi>
                                                <FooterMenu href={item.FooterHref}>
                                                    {item.FooterMenu}
                                                </FooterMenu>
                                            </FooterLi>
                                            })
                                        }
                                        </FooterUl>
                                    </Col>
                                    <Col md="7" lg="7">
                                        <FooterUl>
                                        {
                                            JSONData.headerFooterJson.Footer.FooterCol2.FooterUl2.map((item,idx) => {
                                            return <FooterLi>
                                                <FooterMenu href={item.FooterHref}>
                                                {item.FooterMenu}
                                                </FooterMenu>
                                            </FooterLi>
                                            })
                                        }
                                        </FooterUl>
                                    </Col>
                                </Row>
                            </FooterCol>
                        </Col>
                        <Col md="6" lg="3">
                            <FooterCol>
                                <FooterTitle>{JSONData.headerFooterJson.Footer.FooterCol3.FooterTitle}</FooterTitle>
                                <FooterUl>
                                {
                                    JSONData.headerFooterJson.Footer.FooterCol3.FooterUl.map((item,idx) => {
                                    return <FooterLi>
                                        <FooterMenu href="#">
                                            {item.FooterMenu}
                                        </FooterMenu>
                                    </FooterLi>
                                    })
                                }
                                </FooterUl>
                            </FooterCol>
                        </Col>
                        <Col md="6" lg="3">
                            <FooterCol>
                                <FooterTitle>{JSONData.headerFooterJson.Footer.FooterCol4.FooterTitle}</FooterTitle>
                                <ContactFormFooter />
                            </FooterCol>
                        </Col>
                    </Row>
                </Container>
            </FooterSection>
            <BottomFooterWrapper>
                <Container>
                    <Row>
                        <Col md="8">
                            <BottomFooterPara>
                            {JSONData.headerFooterJson.Footer.BottomFooterPara}
                            <BottomLink href={JSONData.headerFooterJson.Footer.BottomFooterLink}>
                                {JSONData.headerFooterJson.Footer.BottomFooterName}
                            </BottomLink>

                            </BottomFooterPara>
                        </Col>
                        <Col md={4}>
                            <SocialLayout>
                                <FooterSocial href={JSONData.headerFooterJson.Footer.facebooklink} aria-label="FB Footer Link">
                                    <FbIcon/>
                                </FooterSocial>
                                <FooterSocial href={JSONData.headerFooterJson.Footer.twitterlink} aria-label="Twitter Footer Link">
                                    <TwitterIcon/>
                                </FooterSocial>
                                <FooterSocial href={JSONData.headerFooterJson.Footer.linkedinlink} aria-label="LinkedIn Footer Link">
                                    <LinkedinIcon/>
                                </FooterSocial>
                                <FooterSocial href={JSONData.headerFooterJson.Footer.pinterestlink} aria-label="Pinterest Footer Link">
                                    <PinterestIcon/>
                                </FooterSocial>
                            </SocialLayout>
                        </Col>
                    </Row>
                </Container>
            </BottomFooterWrapper>
            </div>
        )
                    }

export default Footer;